'use client'

import { Listbox, ListboxButton, ListboxOptions } from '@headlessui/react'
import {
  themeBackgroundBlurMap,
  themeBackgroundMap,
  themeBorderColorMap,
  themeDivideColorMap,
} from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import { Fragment, type PropsWithChildren, forwardRef } from 'react'
import type { UseFormFieldControlProps } from '../form-field/use-form-field-control.ts'
import {
  MultiselectChips,
  type MultiselectChipsPropsWithRef,
} from './multiselect-chips.tsx'
import { MultiselectPopoutOption } from './multiselect-popout-option.tsx'
import { MultiselectSearch } from './multiselect-search.tsx'
import {
  MultiselectProvider,
  type UseMultiselectProps,
  useMultiselect,
  useMultiselectContext,
} from './use-multiselect.ts'

type MultiselectPopoutInnerProps = PropsWithChildren<
  UseFormFieldControlProps<HTMLButtonElement> &
    Pick<
      MultiselectChipsPropsWithRef<HTMLButtonElement, 'button'>,
      'placeholder' | 'singleLine'
    >
>

type MultiselectPopoutProps = Readonly<
  UseMultiselectProps & MultiselectPopoutInnerProps
>

const MultiselectPopoutInner = forwardRef<
  HTMLButtonElement,
  MultiselectPopoutInnerProps
>(({ children, placeholder, singleLine, ...useFormFieldProps }, ref) => {
  const { selectedItems, onChange } = useMultiselectContext()
  return (
    <Listbox multiple value={selectedItems} onChange={onChange}>
      <ListboxButton as={Fragment}>
        <MultiselectChips
          as="button"
          ref={ref}
          placeholder={placeholder}
          singleLine={singleLine}
          {...useFormFieldProps}
        />
      </ListboxButton>
      <ListboxOptions
        anchor="bottom start"
        className={cn(
          'rounded border divide-y mt-1 w-[var(--button-width)]',
          themeBackgroundBlurMap['blur-xl'],
          themeBackgroundMap['container-secondary'],
          themeBorderColorMap.muted,
          themeDivideColorMap.muted
        )}
      >
        {children}
      </ListboxOptions>
    </Listbox>
  )
})

const MultiselectPopoutComponent = forwardRef<
  HTMLButtonElement,
  MultiselectPopoutProps
>(({ selectedItems, onChange, ...innerProps }, ref) => {
  const context = useMultiselect({ selectedItems, onChange })

  return (
    <MultiselectProvider value={context}>
      <MultiselectPopoutInner {...innerProps} ref={ref} />
    </MultiselectProvider>
  )
})

const MultiselectPopoutNamespace = Object.assign(MultiselectPopoutComponent, {
  Option: MultiselectPopoutOption,
  Search: MultiselectSearch,
})

export { MultiselectPopoutNamespace as MultiselectPopout }
