import type {
  ActivityId,
  DeliverableId,
  FileId,
  OfferId,
  PackageId,
  ProjectId,
  ProjectServiceId,
  UserId,
} from '@mntn-dev/domain-types'
import type { EmptyObject } from 'type-fest'
import { register } from './register.ts'
import type { ProjectPageTab } from './types/tab-types.ts'

const routes = [
  register('/').toRoute({}),
  register('/api/auth/logout').toRoute({}),
  register('/dashboard').toRoute({}),
  register('/login').toRoute({}),
  register('/offers').toRoute({}),
  register('/offers/:offerId').toRoute<{ offerId: OfferId }>({}),
  register('/onboarding').toRoute({}),
  register('/packages').toRoute({}),
  register('/packages/:packageId').toRoute<{ packageId: PackageId }>({}),
  register('/packages/services').toRoute({}),
  register('/profile').toRoute({}),
  register('/projects').toRoute({}),
  register('/projects/:projectId').toRoute<
    { projectId: ProjectId },
    { tab?: ProjectPageTab; activityId?: ActivityId }
  >({}),
  register('/projects/:projectId/services/:projectServiceId').toRoute<{
    projectId: ProjectId
    projectServiceId: ProjectServiceId
  }>({}),
  register('/projects/:projectId/match').toRoute<{ projectId: ProjectId }>({}),
  register('/projects/:projectId/match/maker/:userId').toRoute<{
    projectId: ProjectId
    userId: UserId
  }>({}),
  register('/projects/:projectId/browse').toRoute<{
    projectId: ProjectId
  }>({}),
  register(
    '/projects/:projectId/review/post-production/:deliverableId'
  ).toRoute<{
    projectId: ProjectId
    deliverableId: DeliverableId
  }>({}),
  register('/projects/:projectId/files').toRoute<{
    projectId: ProjectId
  }>({}),
  register('/projects/:projectId/files/:fileId').toRoute<{
    projectId: ProjectId
    fileId: FileId
  }>({}),
  register('/projects/new').toRoute({}),
  register('/settings').toRoute({}),
  register('/users').toRoute({}),
  register('/users/:userId').toRoute<{ userId: UserId }>({}),

  // Testing
  register('/foo').toRoute<EmptyObject, { baz: string }>({}),
  register('/foo/:fooId').toRoute<{ fooId: string }, { baz: string }>({}),
  register('/foo/:fooId/bar/:barId').toRoute<
    { fooId: string; barId: string },
    { baz: string; qux: string; corge?: string }
  >({}),
]

export default routes
