import { useTranslation } from '@mntn-dev/i18n'
import { SystemUserId } from '@mntn-dev/session-manager'
import { Avatar, Feed } from '@mntn-dev/ui-components'
import type { PropsWithChildren } from 'react'
import { UnhandledUnionError } from '../../utilities/src/errors/unhandled-union-error.ts'
import {
  AgreementTermsAccepted,
  CommentCreated,
  PostProductionBrandFeedbackSubmitted,
  PostProductionMakerProposalSubmitted,
  PreProductionBrandFeedbackSubmitted,
  PreProductionMakerProposalSubmitted,
  ProjectCreated,
  ProjectFileAdded,
  ProjectNotMatched,
  ProjectOfferExpired,
  ProjectOfferPending,
  ProjectOffersSent,
  ProjectServiceAdded,
  ProjectStatusChanged,
  ReviewStatusChanged,
  ServiceNoteAdded,
} from './components/activity-details/index.ts'
import type { ActivityProps } from './types.ts'

const ActivityContainer = ({
  activity: { activityId, timestamp, person, actorId },
  indicator,
  children,
  scroll,
}: PropsWithChildren<
  Pick<ActivityProps, 'activity' | 'indicator' | 'scroll'>
>) => {
  const { i18n } = useTranslation('activity')
  const isSystemActivity = actorId === SystemUserId

  return (
    <Feed.Item
      avatar={
        person && !isSystemActivity ? (
          <Avatar.User person={person} />
        ) : (
          <Avatar.Icon name="ExclamationIcon" />
        )
      }
      key={activityId}
      timestamp={timestamp}
      languageId={i18n.language}
      indicator={indicator}
      scroll={scroll}
      dataTestId={`activity-feed-item-${activityId}`}
      dataTrackingId={`activity-feed-item-${activityId}`}
    >
      {children}
    </Feed.Item>
  )
}

export const Activity = (props: ActivityProps) => {
  const {
    activity,
    activity: { details },
  } = props
  const { t } = useTranslation('activity')

  switch (details.activityType) {
    case 'agreement_terms_accepted':
      return (
        <ActivityContainer {...{ ...props }}>
          <AgreementTermsAccepted
            {...{ ...props, activity: { ...activity, details }, t }}
          />
        </ActivityContainer>
      )

    case 'comment_created':
      return (
        <ActivityContainer {...{ ...props }}>
          <CommentCreated
            {...{ ...props, activity: { ...activity, details }, t }}
          />
        </ActivityContainer>
      )

    case 'pre_production_brand_feedback_submitted':
      return (
        <ActivityContainer {...{ ...props }}>
          <PreProductionBrandFeedbackSubmitted
            {...{ ...props, activity: { ...activity, details }, t }}
          />
        </ActivityContainer>
      )

    case 'post_production_maker_proposal_submitted':
      return (
        <ActivityContainer {...{ activity }}>
          <PostProductionMakerProposalSubmitted
            {...{ ...props, activity: { ...activity, details }, t }}
          />
        </ActivityContainer>
      )

    case 'post_production_brand_feedback_submitted':
      return (
        <ActivityContainer {...{ activity }}>
          <PostProductionBrandFeedbackSubmitted
            {...{ ...props, activity: { ...activity, details }, t }}
          />
        </ActivityContainer>
      )

    case 'pre_production_maker_proposal_submitted':
      return (
        <ActivityContainer {...{ ...props }}>
          <PreProductionMakerProposalSubmitted
            {...{ ...props, activity: { ...activity, details }, t }}
          />
        </ActivityContainer>
      )

    case 'project_created':
      return (
        <ActivityContainer {...{ ...props }}>
          <ProjectCreated
            {...{ ...props, activity: { ...activity, details }, t }}
          />
        </ActivityContainer>
      )

    case 'project_file_added':
      return (
        <ActivityContainer {...{ ...props }}>
          <ProjectFileAdded
            {...{ ...props, activity: { ...activity, details }, t }}
          />
        </ActivityContainer>
      )

    case 'project_offer_expired':
      return (
        <ActivityContainer {...{ ...props }}>
          <ProjectOfferExpired
            {...{ ...props, activity: { ...activity, details }, t }}
          />
        </ActivityContainer>
      )

    case 'project_offer_pending':
      return (
        <ActivityContainer {...{ ...props }}>
          <ProjectOfferPending
            {...{ ...props, activity: { ...activity, details }, t }}
          />
        </ActivityContainer>
      )

    case 'project_offers_sent':
      return (
        <ActivityContainer {...{ ...props }}>
          <ProjectOffersSent
            {...{ ...props, activity: { ...activity, details }, t }}
          />
        </ActivityContainer>
      )

    case 'project_not_matched':
      return (
        <ActivityContainer {...{ ...props }}>
          <ProjectNotMatched
            {...{ ...props, activity: { ...activity, details }, t }}
          />
        </ActivityContainer>
      )

    case 'project_service_added':
      return (
        <ActivityContainer {...{ ...props }}>
          <ProjectServiceAdded
            {...{ ...props, activity: { ...activity, details }, t }}
          />
        </ActivityContainer>
      )

    case 'project_status_changed':
      return (
        <ActivityContainer {...{ ...props }}>
          <ProjectStatusChanged
            {...{ ...props, activity: { ...activity, details }, t }}
          />
        </ActivityContainer>
      )

    case 'review_status_changed':
      return (
        <ActivityContainer {...{ ...props }}>
          <ReviewStatusChanged
            {...{ ...props, activity: { ...activity, details }, t }}
          />
        </ActivityContainer>
      )

    case 'service_note_added':
      return (
        <ActivityContainer {...{ ...props }}>
          <ServiceNoteAdded
            {...{ ...props, activity: { ...activity, details }, t }}
          />
        </ActivityContainer>
      )

    default:
      throw new UnhandledUnionError(details)
  }
}
