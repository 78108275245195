import type { TupleToUnion } from 'type-fest'

const themeTextColorNames = [
  'primary',
  'primary-inverse',
  'secondary',
  'secondary-inverse',
  'tertiary',
  'disabled',
  'disabled-inverse',
  'info',
  'link',
  'link-hover',
  'link-pressed',
  'link-subtle',
  'positive',
  'caution',
  'negative',
  'notice',
  'current',
] as const

type ThemeTextColor = TupleToUnion<typeof themeTextColorNames>

const themeTextColorMap: Record<ThemeTextColor, string> = {
  primary: 'text-white/90',
  'primary-inverse': 'text-black/90',
  secondary: 'text-white/60',
  'secondary-inverse': 'text-black/60',
  tertiary: 'text-white/40',
  disabled: 'text-white/20',
  'disabled-inverse': 'text-black/20',
  link: 'text-primary-blue',
  'link-hover': 'text-blue-100',
  'link-pressed': 'text-blue-500',
  'link-subtle': 'text-primary-blue/30',
  info: 'text-primary-blue',
  positive: 'text-primary-green',
  caution: 'text-primary-yellow',
  negative: 'text-primary-red',
  notice: 'text-primary-purple',
  current: 'text-current',
}

const themeHoverTextColorNames = ['cta', 'primary', 'negative'] as const
type ThemeHoverTextColor = TupleToUnion<typeof themeHoverTextColorNames>

const themeHoverTextColorMap: Record<ThemeHoverTextColor, string> = {
  cta: 'hover:text-blue-200',
  primary: 'hover:text-white/90',
  negative: 'hover:text-primary-red',
}

const themeActiveTextColorNames = ['primary', 'negative'] as const
type ThemeActiveTextColor = TupleToUnion<typeof themeActiveTextColorNames>

const themeActiveTextColorMap: Record<ThemeActiveTextColor, string> = {
  primary: 'active:text-white/90',
  negative: 'active:text-primary-red',
}

const themeDisabledTextColorNames = ['primary', 'inverse', 'negative'] as const
type ThemeDisabledTextColor = TupleToUnion<typeof themeDisabledTextColorNames>

const themeDisabledTextColorMap: Record<ThemeDisabledTextColor, string> = {
  primary: 'disabled:text-white/20',
  inverse: 'disabled:text-black/20',
  negative: 'disabled:text-primary-red',
}

const themePlaceholderTextColorNames = ['primary'] as const
type ThemePlaceholderTextColor = TupleToUnion<
  typeof themePlaceholderTextColorNames
>

const themePlaceholderTextColorMap: Record<ThemePlaceholderTextColor, string> =
  {
    primary: 'placeholder:text-white/40',
  }

export {
  themeDisabledTextColorMap,
  themeTextColorMap,
  themeActiveTextColorMap,
  themeHoverTextColorMap,
  themeTextColorNames,
  themePlaceholderTextColorMap,
  type ThemeTextColor,
  type ThemeActiveTextColor,
  type ThemeHoverTextColor,
  type ThemeDisabledTextColor,
  type ThemePlaceholderTextColor,
}
