import { z } from 'zod'

import {
  ReviewRoundNumberSchema,
  ReviewStatusSchema,
} from '../property.models.ts'
import { RoundStatusSchema } from '../round.models.ts'
import {
  ProjectIdSchema,
  ReviewIdSchema,
  RoundIdSchema,
} from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const PostProductionBrandFeedbackSubmittedReviewStatusSchema =
  ReviewStatusSchema.extract(['concepting', 'resolved'])

export type PostProductionBrandFeedbackSubmittedReviewStatus = z.infer<
  typeof PostProductionBrandFeedbackSubmittedReviewStatusSchema
>

export const PostProductionBrandFeedbackSubmittedRoundStatusSchema =
  RoundStatusSchema.exclude(['in_progress'])

export type PostProductionBrandFeedbackSubmittedRoundStatus = z.infer<
  typeof PostProductionBrandFeedbackSubmittedRoundStatusSchema
>

export const PostProductionBrandFeedbackSubmittedActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'post_production_brand_feedback_submitted',
    z
      .object({
        reviewId: ReviewIdSchema,
        projectId: ProjectIdSchema,
        reviewStatus: PostProductionBrandFeedbackSubmittedReviewStatusSchema,
        roundId: RoundIdSchema,
        roundNumber: ReviewRoundNumberSchema,
        roundStatus: PostProductionBrandFeedbackSubmittedRoundStatusSchema,
      })
      .merge(ActivityLinkTargets('project'))
  )
