'use client'
import { themeBackgroundBlurMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import { type Table as ReactTableType, flexRender } from '@tanstack/react-table'
import type { ComponentType } from 'react'
import { Surface } from '../surface/surface.tsx'
import { Table } from '../table/table'
import type { DataTableRowProps } from './data-table-row.tsx'

type Props<T> = {
  className?: string
  table: ReactTableType<T>
  fullHeight?: boolean
  fullWidth?: boolean
  tableId?: string
  rowIdentifier?: string
  scroll?: boolean
  loading?: boolean
  onClick?: (data: T) => void
  rowComponent: ComponentType<DataTableRowProps<T>>
}

export const DataTable = <T,>({
  className = '',
  table,
  fullHeight = false,
  fullWidth = false,
  tableId,
  rowIdentifier = '',
  scroll = false,
  onClick,
  rowComponent: RowComponent,
}: Props<T>) => {
  const handleRowClick = (data: T) => () => {
    if (onClick) {
      onClick(data)
    }
  }

  return (
    <Surface
      className={className}
      height={fullHeight ? 'full' : 'fit'}
      width={fullWidth ? 'full' : 'fit'}
    >
      <div
        className={cn('relative h-full w-full rounded-lg', {
          'overflow-y-auto': scroll,
        })}
      >
        <Table
          width={fullWidth ? 'full' : 'fit'}
          id={tableId}
          dataTestId={`${tableId}-table`}
          className={cn({
            'absolute inset-0': scroll,
          })}
        >
          <Table.Head
            background="container-secondary"
            className={cn(themeBackgroundBlurMap['table-head-blur'], {
              'sticky top-0': scroll,
              'z-10': scroll,
            })}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row
                key={headerGroup.id}
                dataTestId={`${tableId}-table-header-row`}
                className="h-18"
              >
                {headerGroup.headers.map((header) => (
                  <Table.HeaderCell
                    key={header.id}
                    className={cn(
                      header.column.columnDef.meta?.className || '',
                      {
                        'cursor-pointer select-none':
                          header.column.getCanSort(),
                      }
                    )}
                    canSort={header.column.getCanSort()}
                    direction={header.column.getIsSorted()}
                    onClick={header.column.getToggleSortingHandler()}
                    dataTestId={`${tableId}-table-header-cell-${header.id}`}
                    dataTrackingId={`${tableId}-table-header-cell-${header.id}`}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Head>
          <Table.Body background={null}>
            {table.getRowModel().rows.map((row) => (
              <RowComponent
                key={row.id}
                row={row}
                data={row.original}
                tableId={tableId}
                rowIdentifier={rowIdentifier}
                onClick={handleRowClick(row.original)}
              />
            ))}
          </Table.Body>
        </Table>
      </div>
    </Surface>
  )
}
