import { z } from 'zod'

import {
  ReviewRoundNumberSchema,
  ReviewStatusSchema,
} from '../property.models.ts'
import { RoundStatusSchema } from '../round.models.ts'
import {
  ProjectIdSchema,
  ReviewIdSchema,
  RoundIdSchema,
} from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const PostProductionMakerProposalSubmittedReviewStatusSchema =
  ReviewStatusSchema.extract(['reviewing', 'resolving'])

export type PostProductionMakerProposalSubmittedReviewStatus = z.infer<
  typeof PostProductionMakerProposalSubmittedReviewStatusSchema
>

export const PostProductionMakerProposalSubmittedRoundStatusSchema =
  RoundStatusSchema.extract(['in_progress'])

export type PostProductionMakerProposalSubmittedRoundStatus = z.infer<
  typeof PostProductionMakerProposalSubmittedRoundStatusSchema
>

export const PostProductionMakerProposalSubmittedActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'post_production_maker_proposal_submitted',
    z
      .object({
        reviewId: ReviewIdSchema,
        projectId: ProjectIdSchema,
        reviewStatus: PostProductionMakerProposalSubmittedReviewStatusSchema,
        roundId: RoundIdSchema,
        roundNumber: ReviewRoundNumberSchema,
        roundStatus: PostProductionMakerProposalSubmittedRoundStatusSchema,
      })
      .merge(ActivityLinkTargets('project'))
  )
